import React from "react";
import tw, { css } from "twin.macro";

import { useQueryParam, StringParam } from "use-query-params";

import logoURL from "../assets/img/brand/PenLogo.png";
import copy from "../assets/copy";
import bgURL from "../assets/img/Red Card.png";

import useSocialPledge from "../hooks/useSocialPledge";

// ========= COMPONENTS =========
const Wrapper = ({ children }) => (
	<div
		css={[
			tw`
				absolute
				w-full h-full
				text-lg antialiased
				flex flex-col justify-center items-center
				p-20
				bg-cover
			`,
			css`
				background-image: url("${bgURL}");
			`,
		]}
	>
		{children}
	</div>
);

// ========= MAIN =========
const SocialPledge = () => {
	const [uid] = useQueryParam(`u`, StringParam);
	const pledge = useSocialPledge(uid);

	if (!pledge)
		return (
			<div tw="absolute w-full h-full flex justify-center items-center bg-red">
				<div
					css={[
						css`
							width: 100px;
							height: 100px;
							background-image: url("${logoURL}");
							background-position: right;
							background-size: contain;
							background-repeat: no-repeat;
						`,
					]}
				/>
			</div>
		);

	return (
		<Wrapper>
			<div
				css={[
					tw`flex justify-between mb-8 w-full font-centra antialiased`,
				]}
			>
				<div tw="w-1/2 py-2">
					<h1
						css={[
							tw`font-bold uppercase text-white text-lg leading-none`,
						]}
					>
						{copy.labels.expect}
					</h1>
					<h2
						css={[
							tw`italic font-bold text-lg text-white`,
						]}
					>
						{copy.labels.when.ELECTING.text}
					</h2>
				</div>
				<div
					css={[
						tw`relative h-full w-1/6`,
						css`
							background-image: url("${logoURL}");
							background-position: right;
							background-size: contain;
							background-repeat: no-repeat;
						`,
					]}
				/>
			</div>
			<h2
				css={[
					tw`font-bold font-centra text-5xl text-white mb-6 w-full leading-none`,
				]}
			>
				{!pledge?.action &&
				!pledge?.subject &&
				!pledge?.lesson
					? `${copy.pledge.ready} ${copy.pledge.empty}.`
					: `${`${copy.pledge.ready} ${pledge?.action} ${pledge?.subject} ${pledge?.lesson}`.trimEnd()}.`}
			</h2>
			<img alt="Signature" src={pledge.signature} />
		</Wrapper>
	);
};

export default SocialPledge;
