import hagen from "hagen";

import { useEffect, useState } from "react";
import { getPledge } from "../libs/auth";
import useFirebase from "./useFirebase";

export default function useSocialPledge(uid) {
	const firebase = useFirebase();

	const [pledge, setPledge] = useState();

	// when firebase initializes
	useEffect(() => {
		// wait for firebase to actually initialize
		if (!firebase) return;
		if (!uid) return;

		const getPledgeFromFirebase = async () => {
			const p = await getPledge(uid);
			setPledge(p);
		};

		getPledgeFromFirebase();
	}, [firebase, uid]);

	return pledge;
}
