export default {
	learnCategories: {
		spotIt: `Spot It.`,
		checkIt: `Check It.`,
		stopIt: `Stop It.`,
	},

	labels: {
		expect: `What to Expect`,
		when: {
			ELECTING: {
				text: `When You're Electing`,
				color: `white`,
			},
			MEDIA: {
				text: `From the Media`,
				color: `yellow`,
			},
			AFTER: {
				text: `After Ballots are Cast`,
				color: `blue`,
			},
			DURING: { text: `During the Campaign`, color: `pink` },
			VOTING: { text: `When You're Voting`, color: `green` },
		},
	},

	navBar: {
		pledgeWall: `Pledge Wall`,
		quiz: `The Quiz`,
		mission: `Our Mission`,
	},

	pledgeCard: {
		button: `Sign the Pledge`,
	},

	endCard: {
		button: `Spread the Word`,
		subtitle: `Share this quiz`,
	},

	registerCard: {
		heading: `Save your progress!`,
		subheading: `Would you like to save your progress or continue the quiz on another device?`,
		fieldPlaceholder: `Enter your email address`,
		error: `Please enter a valid email address`,
		optIn: `I would like to receive periodic news and updates from PEN America.`,
		actionButton: `Submit`,
	},

	signUp: {
		signIn: `Sign In`,
		signUp: `Sign Up`,
		submit: `Submit`,
		progress: `Want to save your progress and don’t have an account yet? All we need is your email.`,
		delete: `Delete Pledge`,
		retake: `Retake`,
		thanks: `Thanks!`,
		instructions: `We’ve sent you a confirmation email. Check your inbox and follow the instructions to finish up.`,
	},

	pledge: {
		ready: `I'm ready to`,
		post: `Post my pledge to the pledge wall`,
		sign: `Sign here`,
		action: `Submit`,
		login: `Log in`,
		empty: `spread the word`,
		validate: `Oops! Make sure to fill out the dropdown and sign the pledge before submitting.`,
		signIn: `Sign In`,
		skip: `Skip to Quiz »`,
	},

	answered: {
		correct: `Correct!`,
		incorrect: `Not quite.`,
		learn: `Learn More`,
		how: `How to`,
	},

	share: {
		headline: `Have someone who needs to know?`,
		share: `Share this question`,
		quizMessage: `This fall, @PENAmerica is working to combat the surge of disinformation that threatens a free and fair election. Test your knowledge of how to tell fact from fiction.`,
		pledgeMessage: `I signed this @PENAmerica pledge to fight back against election disinformation. You should, too.`,
		hashtag: `WhatToExpect2020`,
		emailSubject: ``,
		emailBody: ``,
	},

	mission: {
		aboutCampaignHeadline: `About This Campaign`,
	},

	pledgeWall: {
		action: `Spread the Word`,
		yours: `Your Pledge`,
		share: `Share your Pledge`,
		join: `Join us in the fight against disinformation`,
		sign: `Sign the Pledge!`,
		flag: `Flag as inappropriate`,
		flagged: `Thanks! We'll look into it.`,
	},
};
